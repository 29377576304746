<template>
  <div>
    <h2>お会計(コインの購入)</h2>
    <b-card
      title=""
    >
      <span class="text-secondary">
        下記の内容をお確かめください。
      </span>
      <div class="mt-1">
        <BasicTable
          :value="tableData"
          :header="fields"
        />
      </div>
      <div class="text-right">
        <span class="price_ text-primary">
          {{ sum.toLocaleString() }}
        </span>円(税込)
      </div>
      <b-row class="mt-5">
        <b-col offset-md="6">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="cancel"
          >
            戻る
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="buy"
          >
            購入する
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script type="text/javascript" src="https://pay.veritrans.co.jp/pop/v1/javascripts/pop.js"
  data-client-key="83bcca3b-d3ca-4153-8567-900e43665d95"></script>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import menulist from '@/components/conf/student/学習PF_受講生_コイン.json'
import addData from '@/firestore/pay/buyCoin'
import BasicTable from '@/components/ui/table/BasicTable.vue'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BasicTable,
  },
  directives: {
    Ripple,
  },
  filters: {
    priceText(val) {
      return val.toLocaleString()
    },
  },
  props: {
  },
  data() {
    return {
      menulist,
      paydata: {},
      sum: 0,
      fields: [
        {
          num: 1,
          key: 'key',
          label: '項目',
        },
        {
          num: 2,
          key: 'value',
          label: '値',
        },
      ],
      tableData: [],
    }
  },
  mounted() {
    this.paydata = menulist.find(v => Number(v.num) === Number(this.$route.query.id))
    this.sum = parseInt(this.paydata.priceValue * 1.1, 10)
    /* eslint-disable  prefer-template */
    this.tableData = [
      { key: 'コイン数', value: this.paydata.coinLabel },
      { key: '価格(税抜)', value: this.paydata.priceLabel },
      { key: '消費税', value: this.paydata.taxLabel },
      // { key: '価格(税込)', value: this.sum.toLocaleString() + '円' },
    ]
    /* eslint-enable */
  },
  methods: {
    async buy() {
      pop.show()
      this.paydata.sum = this.sum
      const data = {
        collection: 'pay',
        data: this.paydata,
      }
      const response = await addData(data)
      if (response.status === 'success') {
        window.console.log('succes', response.docID)
        const uri = 'https://us-central1-jp-career.cloudfunctions.net/api/paydata/'
        const uriget = uri + response.docID
        await axios.get(uriget)
        .then((response) => {
          window.console.log('🐶 paymentKey', response.data.payment_key)
          pop.pay(response.data.payment_key)
        });
        // this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    cancel() {
      this.$router.push({ name: 'student-pay-edit' })
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.$router.push({ name: 'student-pay-list' })
        this.status = 1
      })
    },
  },
}
</script>
<style scoped>
.price_ {
  font-size: 2.4rem;
  font-weight: 400;
}
</style>
