<template>
  <b-row class="match-height">
    <b-col lg="8">
      <ConfirmPay />
    </b-col>
    <b-col lg="4">
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ConfirmPay from '@/components/student/pay/ConfirmPay.vue'
import Sidebar from '@/components/student/sidebar/test/Sidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    ConfirmPay,
    Sidebar,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
</style>
